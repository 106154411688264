$.fn.uconfyCollapse = function () {

  function plugin($el, data) {
    const $target = $($el.attr('data-target'));
    const labelShow = data['labelShow'];
    const labelHide = data['labelHide'];
    const $label = $el.find('.label');

    function showOrHideTarget() {
      if ($target.attr('data-shown') === '0') {
        $target.hide();
        if (labelShow && labelHide) {
          $label.text(labelShow);
        }
      } else {
        $target.show();
        if (labelShow && labelHide) {
          $label.text(labelHide);
        }
      }
    }

    $el.click(() => {
      if ($target.attr('data-shown') === '0') {
        $target.attr('data-shown', '1');
      } else {
        $target.attr('data-shown', '0');
      }
      showOrHideTarget();
    });

    showOrHideTarget($target);
  }

  // Apply the plugin
  return this.each(function () {
    const $el = $(this);
    plugin($el, $el.data());
  });
};
