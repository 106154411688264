$.fn.uconfyCheckboxSelectMultiple = function () {

  const applyPlugin = function ($el) {

    $el.find('.clear-cb-btn').on('click', function () {
      const $parent = $(this).parent().parent();
      $parent.find('.form-check input').prop('checked', false);
    });

    $el.find('.select-all-cb-btn').on('click', function () {
      const $parent = $(this).parent().parent();
      $parent.find('.form-check input').prop('checked', true);
    });

    const $tail = $el.find('.cb-options-tail');

    const getNumChecked = function ($anEl) {
      return $anEl.find('input[type="checkbox"][checked]').length;
    };

    const getNumItems = function ($anEl) {
      return $anEl.find('input[type="checkbox"]').length;
    };

    $tail.on('show.bs.collapse', function () {
      const $collapse = $(this).siblings('.cb-options-tail-collapse');
      const numItems = getNumItems($(this));
      $collapse.html(`<i class="fas fa-minus"></i> Show ${numItems} less...`);
      console.log(getNumChecked($collapse.parent()));
    });

    $tail.on('hide.bs.collapse', function () {
      const $collapse = $(this).siblings('.cb-options-tail-collapse');
      const numItems = getNumItems($(this));
      $collapse.html(`<i class="fas fa-plus"></i> Show ${numItems} more...`);
    });

    // First, we update titles on collapse anchors to represent the number of items hidden:
    const $collapse = $el.find('.cb-options-tail-collapse');
    if ($collapse.length > 0) {
      const $collapsedDiv = $collapse.siblings('.cb-options-tail');
      $collapse.html(`<i class="fas fa-plus"></i> Show ${getNumItems($collapsedDiv)} more...`);
    }

    // Secondly, force un-collapse of any select where at least one item checked:
    if (getNumChecked($el) > 0) {
      $el.find('.cb-options-tail').collapse('show');
    }
  };

  // Apply the plugin
  return this.each(function () {
    applyPlugin($(this));
  });
};
