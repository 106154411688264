$.fn.uconfy = function ({
  afterSubmit = 'reload',
  keepPreloaded = false,
} = {}) {
  return this.each(function () {
    const $el = $(this);
    $el.find('form[data-toggle="instant-submit"]').uconfyInstantSubmit({afterSubmit});
    // $el.find('[data-toggle="uconfy-modal-confirm"]').uconfyModalConfirm({afterSubmit});
    $el.find('[data-toggle="modal-confirm"]').uconfyModalDialog({loadBody: false});
    $el.find('[data-toggle="modal-dialog"]').uconfyModalDialog({loadBody: true});
    $el.find('[data-toggle="sortable"]').uconfySortable();

    $el.find('.uconfy-alert-dismissible').uconfyAutoHideAlert();

    $el.find('.uconfy-file-control').uconfyFileControl();
    $el.find(COMPONENT_ROOT_QUERY).uconfyComponent({keepPreloaded});
    $el.find('.uconfy-select-user').uconfySelectUser();

    $el.find('input[data-toggle="tags"]').tagsinput({tagClass: 'badge badge-primary'});
    $el.find('[data-toggle="select"]').select2({});

    $el.find('[data-plugin="ajax-form"]').uconfyAjaxForm();
    $el.find('[data-enable-on]').uconfyEnableOn();

    $el.find('.uconfy-affiliation-form').uconfyAffiliationForm();

    $el.find('button[data-plugin="uconfy-collapse"]').uconfyCollapse();
    // $el.find('.collapse').collapse();

    $el.find('[data-plugin="uconfy-list-toggler"]').uconfyListToggler();
    $el.find('[data-plugin="uconfy-select-reviewer-invitation"]').uconfySelectReviewerInvitation();
    $el.find('[data-plugin="uconfy-select-paper-reviewer"]').uconfySelectPaperReviewer();
    $el.find('[data-plugin="uconfy-input-range"]').uconfyInputRange();
    // $el.find('[data-plugin="uconfy-textarea"]').uconfyTextArea();
    $el.find('[data-plugin="uconfy-review-form"]').uconfyReviewForm();

    $el.find('[data-plugin="uconfy-checkbox-select-multiple"]').uconfyCheckboxSelectMultiple();

    ApplyCustomInputFile();
  });
};

$(document).ready(() => {
  $(document).uconfy({keepPreloaded: true});
});
