/**
 * Textarea plugin for tracking input words number.
 *
 * Data arguments:
 *
 * - data-min-words-num: minimum number of words (optional)
 * - data-target: a reference to <textarea> element inside <div>
 * - data-label: a reference to <div> or <span> inside <div> where
 *       current validation status will be displayed
 * - data-valid-class: class that should be applied to a label when input
 *       value is valid
 * - data-invalid-class: class that should be applied to a label when
 *       input value is invalid
 *
 *  Example:
 *
 *  <div data-plugin="uconfy-textarea"
 *       data-target=".uconfy-textarea-input"
 *       data-label=".uconfy-textarea-label"
 *       data-valid-class="text-success"
 *       data-invalid-class="text-danger"
 *       data-min-words-num="50">
 *   <textarea class="uconfy-textarea-input"></textarea>
 *   <div class="uconfy-textarea-label"></div>
 *  </div>
 */
$.fn.uconfyTextArea = function () {
  const applyPlugin = function ($el) {
    const data = $el.data();
    const minWordsNum = parseInt(data['minWordsNum']);
    const $target = $el.find($(data['target']));
    const $label = $el.find($(data['label']));
    const validClass = data['validClass'];
    const invalidClass = data['invalidClass'];

    function countWords(s) {
      const words = s.trim().split(/\s+/);
      if (words.length === 1 && words[0] === "") {
        return 0;
      }
      return words.length;
    }

    function validateInput() {
      const wordsNum = countWords($target.val());
      const isValid = wordsNum >= minWordsNum;
      const plural = wordsNum === 1 ? '' : 's';
      $label.text(`${wordsNum} word${plural} | min: ${minWordsNum}`);
      if (isValid) {
        $label.removeClass(invalidClass);
        if (!$label.hasClass(validClass))
          $label.addClass(validClass);
      } else {
        $label.removeClass(validClass);
        if (!$label.hasClass(invalidClass))
          $label.addClass(invalidClass);
      }
    }

    $target.on('input', validateInput);
    validateInput();
  };

  return this.each(function () {
    applyPlugin($(this));
  });
};
