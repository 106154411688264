/**
 * When applied on a <input> or <button>, enables this component
 * only when a field referenced with `data-enable-on` is not empty.
 *
 * @return {*}
 */
$.fn.uconfyEnableOn = function () {
  const applyPlugin = function ($element) {
    if (!$element.is('button') && !$element.is('input')) {
      console.log('[ERROR] uconfyEnableOn() is allowed to be called on' +
        'BUTTON and INPUT elements only');
      return;
    }
    const data = $element.data();
    const $target = $(data['enableOn']);
    $target.change(() => {
      const value = $target.val();
      $element.prop('disabled', !value);
    });
    $element.prop('disabled', !$target.val());
  };

  return this.each(function () {
    applyPlugin($(this));
  });

};
