$.fn.uconfySortable = function () {
  return this.each(function () {
    const $el = $(this);
    const data = $el.data();

    const updateIndexes = function () {
      $el.find('.uconfy-sortable-item').each((index, item) => {
        $(item).find('.uconfy-sortable-item-index').text(index + 1);
      });
    };

    const getIDs = function () {
      const $items = $el.find('.uconfy-sortable-item');
      const ids = $.map($items, item => $(item).attr('data-id'));
      $.post({
        url: data.action || '',
        headers: {'X-CSRFToken': getCookie('csrftoken')},
        data: {ids}
      });
    };

    $el.sortable({
      revert: true,
      stop: () => {getIDs(); updateIndexes();},
      create: updateIndexes(),
    })
  });
};
