/**
 *
 * <div data-plugin="ajax-form" data-load-into="form">
 *
 *   <form action="..." method="POST">
 *     ...
 *   </form>
 *
 *   <button data-toggle="submit">Submit</button>
 * </div>
 *
 * Attributes:
 *
 * - data-plugin: "ajax-form", mandatory
 *
 * - data-reload-target: what to reload after successful submit:
 *    1) if 'parent', reload parent component
 *    2) if '#...', reload the component that is referenced here
 *    3) in all other cases, reload window
 *
 * @return {*}
 */
$.fn.uconfyAjaxForm = function ({success} = {}) {

  const applyPlugin = function ($element) {
    const data = $element.data();
    const $form = $element.find('form');
    const $root = $element.find(data['loadInto'] || 'form');
    const toggler = $element.find('[data-toggle="submit"]');

    // If button has `data-toggle="modal"` attribute set, assume that
    // submit button is really inside the modal, so show it on click.
    // NOTE: here I call `.modal('show')` directly, since default
    //       Bootstrap plugin doesn't work 10/10 times for some reason.
    const modalToggler = $element.find('[data-toggle="modal"]');
    if (!!modalToggler && !!modalToggler[0]) {
      const $modal = $element.find(modalToggler.attr('data-target'));
      modalToggler.click(() => $modal.modal('show'));
    }

    const reloadTargets = getReloadTargets(data);

    // Load form
    $.get($form.attr('action'), ({html}) => {
      $root.html(html);
      $root.uconfy();
    });

    // Submit form on toggler click
    toggler.click(() => $form.submit());

    // Handle submit via AJAX:
    $form.submit((event) => {
      event.preventDefault();
      sendAjaxForm({
        $form,
        requestTarget: $form.attr('action'),
        requestMethod: $form.attr('method') || "POST",
        successCallback: response => {
          if (success) {
            success();
          }
          $root.html(response.html);
          $root.uconfy();
          reloadTargets.forEach((target, index) => {
            reloadComponentOrWindow($element, target)
          });
        },
        formErrorCallback: response => {
          // If form errors found, reload only
          $root.html(response.html);
          $root.uconfy();
        },
        failureCallback: error => {
          // In case of error, just output to console.
          console.log(error);
        },
      });
      return false;
    });
  };

  return this.each(function () {
    applyPlugin($(this));
   });
};
