$.fn.uconfyAffiliationForm = function () {
  return this.each(function () {
    const dialog = $(this);
    const isActualInput = dialog.find('.uconfy-affiliation-actual').find('input');
    const yearRetiredInput = dialog.find('.uconfy-affiliation-retired').find('input');
    const thisYear = (new Date()).getFullYear();

    if ($(isActualInput).prop('checked')) {
      yearRetiredInput.val('');
      yearRetiredInput.prop('disabled', true);
    }

    isActualInput.change(function () {
      if (this.checked) {
        yearRetiredInput.val('');
        yearRetiredInput.prop('disabled', true);
      } else {
        const currYearValue = yearRetiredInput.val();
        yearRetiredInput.val(currYearValue || thisYear);
        yearRetiredInput.prop('disabled', false);
      }
    });
  });
};
