/**
 * Toggle password text on clicking and holding an eye-icon.
 *
 * (!!!) Assumes font-awesome is used! Toggles only `fa-` part of the
 * icon class, without `fas` or `far`.
 *
 * Options:
 * - visiblePlaceholder: text to show when password is visible and empty;
 * - visibleIconClass: icon class when password is visible;
 * - hiddenPlaceholder: text to show when password is hidden and empty;
 * - hiddenIconClass: icon class when password is hidden.
 *
 * @return {*}
 */
$.fn.uconfyPassword = function ({
  visiblePlaceholder = 'Enter your password',
  hiddenPlaceholder = '********',
  visibleIconClass = 'fa-eye',
  hiddenIconClass = 'fa-eye-slash',
} = {}) {
  return this.each(function () {
    var block = $(this);
    var input = block.find('input');
    var toggler = block.find('.uconfy-password-toggler');
    var icon = toggler.is('i') ? toggler : toggler.find('i');
    toggler
      .mousedown(function () {
        input.attr('type', 'text');
        input.attr('placeholder', visiblePlaceholder);
        icon.removeClass(visibleIconClass);
        icon.addClass(hiddenIconClass);
      })
      .mouseup(function () {
        input.attr('type', 'password');
        input.attr('placeholder', hiddenPlaceholder);
        icon.addClass(visibleIconClass);
        icon.removeClass(hiddenIconClass);
      });
  });
};

$(document).ready(function (options) {
  $('.uconfy-password').uconfyPassword(options);
});
