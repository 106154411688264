/**
 * Review form plugin.
 *
 * TODO: generalize with uconfyAjaxForm plugin
 *
 * Accepts 'data-reload-N' attributes for reloading components after
 * submission.
 */
$.fn.uconfyReviewForm = function () {
  const createModel = ({questions, onAllValid, onAnyInvalid, onValid, onInvalid}) => {
    //
    // Stores questions (id => {id, valid}) and a flag indicating
    // that all tracked fields are valid.
    //
    const state = {
      questions: {},
      allValid: false,
    };

    //
    // Parse questions and build state (mutates state):
    //
    Object.values(questions).forEach(question => {
      if (question.type === 'text') {
        state.questions[question.id] = {id: question.id, valid: false};
        onInvalid(question.id);
      }
    });
    state.allValid = Object.keys(state.questions).length === 0;

    // Initial calls to handlers:
    if (state.allValid)
      onAllValid();
    else onAnyInvalid();

    const setQuestionValid = (id, valid) => {
      const question = state.questions[id];
      if (question.valid !== valid) {
        question.valid = valid;
        const objectUpdateHandler = valid ? onValid : onInvalid;
        objectUpdateHandler(id);

        // Update all valid:
        const allValid = Object.values(state.questions).every(q => q.valid);
        if (state.allValid !== allValid) {
          state.allValid = allValid;
          const formUpdateHandler = allValid ? onAllValid : onAnyInvalid;
          formUpdateHandler();
        }
      }
    };

    return {
      setValid: (id, valid) => setQuestionValid(id, valid),
    }
  };

  /**
   * Helper: counts a number of words in a given string.
   * @param s
   * @return {number|*}
   */
  function countWords(s) {
    const words = s.trim().split(/\s+/);
    if (words.length === 1 && words[0] === "") {
      return 0;
    }
    return words.length;
  }

  /**
   * Plugin applied to a form
   * @param $el
   */
  const applyPlugin = function ($form) {
    if (!$form.is('form')) {
      console.log('[uconfyReviewForm] ERROR: must be used on <form>');
    }

    const $submitBtn = $form.find('[data-toggle="submit"]');
    const $invalidLabel = $form.find('.uconfy-form-invalid-label');
    const questions = {};

    // Extract and store question id:
    $form.find('textarea').each(function () {
      const $ta = $(this);
      const data = $ta.data();
      const id = parseInt(data['questionId']);
      questions[id] = {
        type: 'text',
        id: id,
        $control: $ta,
        $label: $form.find(data['label']),
        minWordsNum: data['minWordsNum'],
        validClass: data['validClass'],
        invalidClass: data['invalidClass'],

        updateLabelClass: function (valid) {
          if (valid) {
            this.$label.removeClass(this.invalidClass);
            if (!this.$label.hasClass(this.validClass))
              this.$label.addClass(this.validClass);
          } else {
            this.$label.removeClass(this.validClass);
            if (!this.$label.hasClass(this.invalidClass))
              this.$label.addClass(this.invalidClass);
          }
        },

        setLabelText: function (text) {
          this.$label.text(text);
        },

        validateInput: function () {
          const wordsNum = countWords(this.$control.val());
          const plural = wordsNum === 1 ? '' : 's';
          const text = `${wordsNum} word${plural} | min: ${this.minWordsNum}`;

          this.setLabelText(text);
          model.setValid(this.id, wordsNum >= this.minWordsNum);
        },
      };

      // Then, attach 'input' handler:
      $ta.on('input', () => questions[id].validateInput());
    });

    // Create a model:
    const model = createModel({
      questions: questions,
      onAllValid: () => {
        $submitBtn.prop('disabled', false);
        $invalidLabel.addClass('d-none');
      },
      onAnyInvalid: () => {
        $submitBtn.prop('disabled', true);
        $invalidLabel.removeClass('d-none');
      },
      onValid: id => questions[id].updateLabelClass(true),
      onInvalid: id => questions[id].updateLabelClass(false),
    });

    // Finally, validate input for all questions:
    Object.values(questions).forEach(question => question.validateInput());
  };

  return this.each(function () {
    applyPlugin($(this));
  });
};
