$.fn.uconfySelectPaperReviewer = function () {

  const TEMPLATE_HTML = `
    <div class="d-flex align-items-center justify-content-between py-3 border-bottom">
      <img src="" alt="" class="avatar rounded rounded-circle mr-3">
      <div class="d-flex flex-column justify-content-between align-items-stretch flex-fill mr-3">
        <div class="d-flex mb-1">
          <div class="js--name font-weight-900 text-dark"></div>
          <div class="js--proximity-container font-weight-800 mx-3">
            <i class="mr-1"></i>
            <span class="font-weight-800 js--proximity"></span>
          </div>
          <div class="js--num-reviews mx-3 font-weight-800">
            <span class="text-muted">C/R/A = </span>
            <span class="js--num-reviews-completed text-success"></span>/<span class="font-weight-800 js--num-reviews-rejected text-danger"></span>/<span class="font-weight-800 js--num-reviews-assigned text-primary"></span> 
          </div>
          <div class="font-weight-500 text-muted text-xs ml-auto">
            #<span class="js--id"></span>
          </div>
        </div>
        <div class="js--missing-topics-container"></div>
      </div>
    </div>
  `;

  const MISSING_TOPICS_HTML = `
    <div class="mt-1 pl-3 border-left border-warning">
      <div class="text-sm font-weight-800">Missing topics:</div>
      <ul class="list-unstyled text-xs pl-2">
      </ul>
    </div>
  `;

  const SELECTED_HTML = `
    <div class="d-flex align-items-center">
      <img src="" alt="" class="avatar avatar-sm rounded rounded-circle mr-3" style="width: 28px; height: 28px;">
      <div class="js--name font-weight-900 text-dark text-sm"></div>
    </div>
  `;

  const EMPTY_SELECTION_HTML = `
    <div class=""></div>
  `;

  function applyPlugin($el) {
    const select = $el.find('select');
    const $button = $el.find('.uconfy-submit');
    const url = select.attr('data-url');

    select.select2({
      ajax: {
        url: url,
        dataType: 'json',
        delay: 100,
        data: function (params) {
          return {
            q: params.term,  // limit to 'q' only
          }
        }
      },
      placeholder: 'Search for a reviewer',
      minimumInputLength: 0,
      templateSelection: formatSelection,
      templateResult: formatProfile,
      allowClear: true,
    });

    function formatProfile(reviewer) {
      const $container = $(TEMPLATE_HTML);

      const name = reviewer['name'];
      const profileId = reviewer['profileId'];
      const avatarUrl = reviewer['avatarUrl'];
      const proximity = reviewer['proximity'];
      const missingTopics = reviewer['missingTopics'];
      const numReviewsAssigned = reviewer['numReviewsAssigned'];
      const numReviewsRejected = reviewer['numReviewsRejected'];
      const numReviewsCompleted = reviewer['numReviewsCompleted'];

      $container.find('img').attr('src', avatarUrl);
      $container.find('.js--name').text(name);
      $container.find('.js--id').text(profileId);
      $container.find('.js--num-reviews-completed').text(numReviewsCompleted);
      $container.find('.js--num-reviews-rejected').text(numReviewsRejected);
      $container.find('.js--num-reviews-assigned').text(numReviewsAssigned);

      const $proximityEl = $container.find('.js--proximity');
      $proximityEl.text(`${proximity}%`);
      if (proximity > 90)
        $proximityEl.addClass('text-success');
      else if (proximity > 45)
        $proximityEl.addClass('text-warning');
      else
        $proximityEl.addClass('text-danger');

      if (missingTopics && missingTopics.length > 0) {
        const $missingTopics = $(MISSING_TOPICS_HTML);
        const $missingTopicsUl = $missingTopics.find('ul');
        missingTopics.forEach((text) => {
          $missingTopicsUl.append($(
            `<li><i class="fas fa-times"></i> ${text}</li>`
          ));
        });
        $container.find('.js--missing-topics-container').append($missingTopics);
      }

      return $container;
    }

    function formatSelection(reviewer) {
      if (reviewer.id) {
        const $container = $(SELECTED_HTML);
        $container.find('img').attr('src', reviewer['avatarUrl']);
        $container.find('.js--name').text(reviewer['name']);
        return $container;
      }
      return $(EMPTY_SELECTION_HTML).text(reviewer.text);
    }

    $button.click(function () {
      var selectedData = select.select2('data');
      if (selectedData) {
        const reviewer = selectedData[0];
        $.ajax({
          url: reviewer['assignUrl'],
          method: "POST",
          headers: {'X-CSRFToken': getCookie('csrftoken')},
          complete: () => {
            const reloadTargets = getReloadTargets($el.data());
            if (reloadTargets.length === 0) {
              window.location.reload();
            } else {
              reloadTargets.forEach(
                (target) => reloadComponentOrWindow($el, target)
              );
            }
          }
        });
      }
    });
  }

  return this.each(function () {
    applyPlugin($(this));
  });
};
