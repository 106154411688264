/**
 * Reload component or page depending on 'reloadTarget' parameter value.
 *
 * If `reloadTarget === "component"`, then the closest parent of the element
 * on which the plugin is run (NOT the modal) with 'uconfy-component-root'
 * class will be reloaded.
 *
 * If `reloadTarget` contains an href to another element, it is treated as
 * the component to be reloaded (e.g., `reloadTarget === "#some-component"`);
 * note, that this component must also have 'uconfy-component-root' class.
 *
 * In other cases or if component is not found, the window is reloaded.
 *
 * @param $element - jQuery element that toggles reload
 * @param reloadTarget - `'parent'`, `'window'` or ID of the element to reload
 * @param next - URL where to move if `reloadTarget === 'next'`
 */
function reloadComponentOrWindow($element, reloadTarget, next)
{
  let $component = undefined;

  if (reloadTarget !== 'next' && reloadTarget !== 'window') {
    // First, try to find a target
    if (reloadTarget !== 'component' && reloadTarget !== 'parent') {
      $component = $(reloadTarget);
    }

    // If not found directly, try to find a parent
    if (!$component || !$component[0]) {
      $component = $element.parents(COMPONENT_ROOT_QUERY);
    }

    // If found either parent, or a direct component, reload it and finish
    if (!!$component && !!$component[0]) {
      if ($component.hasClass(COMPONENT_ROOT_CLASS)) {
        $component.uconfyComponent();
      }
      else if ($component.attr('data-plugin') === 'ajax-form') {
        $component.uconfyAjaxForm();
      }

      return;
    }
  }

  // If next is defined, reload to it:
  if (reloadTarget === 'next' && next !== undefined) {
    window.location = next;
    return;
  }

  // By default, reload window
  window.location.reload();
}


/**
 * Send form via AJAX and check response for 'success' field.
 *
 * If response either contains 'success', which is set to True, or it
 * misses 'success' field, call `successCallback(response)`.
 *
 * If response contains 'success', which is set to False, call
 * `formErrorCallback(response)`.
 *
 * In case of failure, call `failureCallback(error)`.
 *
 * @param $form
 * @param requestTarget
 * @param requestMethod
 * @param successCallback
 * @param formErrorCallback
 * @param failureCallback
 */
function sendAjaxForm({
    $form = undefined,
    requestTarget = undefined,
    requestMethod = undefined,
    successCallback = (response) => {},
    formErrorCallback = (response) => {},
    failureCallback = console.log,
} = {})
{
    const formData = new FormData($form[0]);
    $.ajax({
      url: requestTarget,
      method: requestMethod,
      data: formData,
      processData: false,
      contentType: false,
      // dataType: "JSON",
      headers: {'X-CSRFToken': getCookie('csrftoken')},
    })
      .done(response => {
        if (!('success' in response) || response.success) {
          successCallback(response);
        } else {
          formErrorCallback(response);
        }
      })
      .fail(error => {
        failureCallback(error)
      })
}


function getReloadTargets(data) {
  return Object.keys(data)
      .filter(key => key.startsWith('reloadTarget'))
      .map(key => data[key]);
}
