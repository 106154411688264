/**
 * Plugin loads the component passed via `data-url` attribute inside the
 * given element. All element attributes except `data-url` are passed to
 * the URL via GET query string.
 *
 * After the component is loaded, run `uconfy()` function on it to apply
 * all plugins.
 *
 * (!!!) GET sent to the URL must return a JSON response with `html` field.
 *
 * @return {*}
 */
$.fn.uconfyComponent = function ({keepPreloaded = false} = {}) {
  function plugin($el, data) {
    // Extract element attributes to pass all except `data-url` via GET.
    const reqData = Object.keys(data)
      .filter(key => key !== 'url')
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    // Load the component HTML from `response.html` property.
    if (!keepPreloaded || data['preloaded'] !== 'yes') {
      $.get(data.url, reqData, ({html}) => {
        $el.html(html);
        $el.uconfy();
      });
    }
  }

  // Apply the plugin
  return this.each(function () {
    const $el = $(this);
    plugin($el, $el.data());
  });
};
