$.fn.uconfyInstantSubmit = function ({
  afterSubmit = 'reload',
} = {}) {
  function plugin($form) {
    const data = $form.data();

    if (!$form.is('form')) {
      console.log(
        '[uconfyInstantSubmit] ERROR: must be used on FORM element only'
      );
    }

    const reloadTargets = getReloadTargets(data);

    $form.find('input').on('change', () => {
      $.ajax({
        url: $form.attr('action') || "",
        method: $form.attr('method') || "POST",
        data: new FormData($form[0]),
        processData: false,
        contentType: false,
        headers: {'X-CSRFToken': getCookie('csrftoken')},
        // dataType: "json",
        complete: () => {
          if (afterSubmit === 'reload' || data.reload === 'window') {
            window.location.reload();
            return;
          }
          reloadTargets.forEach((target, index) => {
            reloadComponentOrWindow($form, target)
          });
        }
      });
    });
  }

  return this.each(function () {
    plugin($(this));
  });
};
