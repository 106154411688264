/**
 * Plugin should be applied to <div> containing <input type="range">.
 *
 * Depending on current value, label content and color will change. Right now
 * we use these classes:
 *
 * - value < 4:      ".text-danger" and ".border-danger"
 * - 4 <= value < 8: ".text-warning" and ".border-warning"
 * - 8 <= value:     ".text-success" and ".border-success"
 *
 * Data arguments supported:
 *
 * - data-target: a reference to <input> **inside** <div> with a plugin
 * - data-label: a reference to a <div> or <span> which text will be updated
 *        with current input value
 *
 *  Structure of a div with a plugin:
 *
 *  <div data-plugin="uconfy-input-range"
 *       data-target=".uconfy-input-range-input"
 *       data-label=".uconfy-input-range-label">
 *    <input type="range"
 *           class="uconfy-input-range-input"
 *           min="1" max="10" value="5">
 *    <div class="uconfy-input-range-label">
 *      <!-- Text will be filled by the plugin -->
 *    </div>
 *  </div>
 */
$.fn.uconfyInputRange = function () {
  const applyPlugin = function ($el) {
    const data = $el.data();
    const $target = $el.find($(data['target']));
    const $label = $el.find($(data['label']));

    function updateLabel() {
      var value = $target.val();
      if (value < 4) {
        $label.removeClass('border-success text-success border-warning text-warning');
        if (!$label.hasClass('border-danger'))
          $label.addClass('border-danger text-danger');
      } else if (value < 8) {
        $label.removeClass('border-success text-success border-danger text-danger');
        if (!$label.hasClass('border-warning'))
          $label.addClass('border-warning text-warning');
      } else {
        $label.removeClass('border-warning text-warning border-danger text-danger');
        if (!$label.hasClass('border-success'))
          $label.addClass('border-success text-success');
      }
      $label.text(value);
    }

    $target.change(updateLabel);
    updateLabel();
  };

  return this.each(function () {
    applyPlugin($(this));
  })
};
