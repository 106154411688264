/** Plugin for displaying form or confirmation dialogs in modal windows.
 *
 * OVERVIEW
 * ========
 *
 * The plugin is applied to clickable component (e.g., <button>).
 *
 * It carries four things:
 *
 * 1) dynamic loading a Bootstrap 4 modal window and inserting into the DOM
 *    from a path given in `data-component-url` attribute of the element
 *    the plugin is called on.
 *
 * 2) (optional) dynamic loading of a form and inserting into the loaded modal body.
 *   This action is performed if `loadBody` plugin parameter is set to `true`.
 *
 * 3) sending a request to the server via `data-action` URL using `data-method` method
 *   when user clicks submit button.
 *
 * 4) either re-rendering a form in the modal body, if the server responded
 *   with HTTP 200 response but indicated that the form was invalid, or
 *   displaying a success/failure message for a couple of seconds, hiding and
 *   removing from DOM the modal then.
 *
 *
 * (I) Loading the modal
 * ---------------------
 *
 * When the element the plugin is applied on is clicked, an AJAX GET is sent
 * to the URL indicated with `data-component-url` attribute.
 *
 * When sending a GET request, all `data-...` attributes are passed in query string
 * to `data-component-url`, so they may be used when rendering a modal form.
 * These attributes are passed as returned by `$(element).data()` call:
 * in camelCase and without `data-` prefix. Consult documentation for the
 * handle for more information about supported `data-` parameters.
 *
 * The response is expected to provide a JSON with a modal HTML code in 'html' key
 * and this modal ID in 'id' key.
 *
 *     <button ... data-component-url="/component" ...>
 *
 *     GET /component?... ==> JSON {'id', 'html'} with modal dialog HTML and its ID.
 *
 *    Example schema of the returned HTML:
 *
 *      <div class="modal ..." id="this-modal-ID">
 *        ...
 *        <div class="modal-header ... hide-at-finish">.
 *          ..
 *        </div>
 *        <div class="modal-body ...">
 *          ...
 *        </div>
 *        <div class="modal-footer ... hide-at-finish">
 *          ...
 *          <button type="button" data-toggle="submit" ...>...</button>
 *          ...
 *        </div>
 *        ...
 *        <template class="success-body">...</template>
 *        <template class="error-body">...</template>
 *        ...
 *      </div>
 *
 *  Three things to be noticed here:
 *
 *  1) some elements may have .hide-at-finish class set. The are hidden when
 *     the modal is going to be closed after processing the request
 *     (which was triggered by the main button click)
 *
 *  2) at least one button has `data-toggle="submit"` attribute set.
 *     This button is the primary (confirm, submit). Since it is NOT carried
 *     inside any form and doesn't reference one, its type is set to "button".
 *     However, pressing on this will cause request being sent to the server.
 *
 *  3) there are two templates residing somewhere in the modal with
 *     classes '.success-body' and '.error-body'. These templates replace
 *     '.modal-body' after the user presses the submit button and receives
 *     a response without form error or with non-200 HTTP status code.
 *
 *
 * (II) Optional loading of the modal body
 * ---------------------------------------
 *
 * If `loadBody` parameter is set to `true`, then after the modal is loaded,
 * a form is loaded and put inside the modal `.modal-body` element.
 *
 * The form is obtained from a URL passed in `data-action` attribute.
 *
 *   <button ... data-action="/form" ...>
 *
 *   GET /form ==> JSON {'success', 'html'} with form HTML and `success=true`
 *
 * The same `data-action` URL is then used step (III).
 *
 *
 * (III) Submitting data to server
 * -------------------------------
 *
 * When the user clicks a button with `data-toggle="submit"`, the data is
 * sent to the `data-action` URL using `data-method` method (typically, "POST")
 *
 * The server response with a JSON that may contain 'html' and 'success' keys:
 *
 * - `response.html` provides HTML that should be loaded inside .modal-body
 *     (form with errors) if `response.success === false`.
 *
 * - `response.success` indicates whether request processing was successful
 *     taking into account data sent. Note, that this JSON is sent only
 *     in HTTP 200 responses, so if the server failed due to missing path,
 *     credentials error or any other problem, no `response.success` will
 *     be available.
 *
 * If server responded with HTTP 200 without `success`, this response is
 * also treated as successful.
 *
 *
 * (IV) Handling server response
 * -----------------------------
 *
 * If server responded with HTTP 200, two cases may arrive:
 *
 * - response contained `success === false`:
 *
 *      in this case, data from `response.html` is rendered in '.modal-body'
 *      and modal is kept open.
 *
 * - response did not contain `success` or contained `success === true`:
 *
 *     in this case a content of
 *        <template class="success-body">...</template>
 *     is loaded into '.modal-body' and the modal is hidden in a couple of seconds.
 *
 * If server responded with HTTP 4xx or 5xx, a content of
 *    <template class="error-body">...</template>
 * is loaded into <div class='.modal-body'>...</div> and the modal is hidden.
 *
 * When displaying final screens from templates, plugin also hides all elements
 * with 'hide-at-finish' class.
 *
 * After the modal is closed ('bs.modal.hidden' event), it is removed from the DOM.
 *
 *
 * DATA ARGUMENTS USED BY THE PLUGIN:
 * ==================================
 *
 * Note, that there could be more `data-` attributes, since all of them are sent
 * to the server when loading the component and may be utilized there.
 *
 * - `data-component-url`:
 *      A URL where to get modal component.
 *
 *      [!!] response from this URL spec: JSON {
 *        html: (mandatory) modal HTML,
 *        id: (mandatory) ID assigned to this modal (typically, a random UUID),
 *      }
 *
 * - `data-action`:
 *      A URL where to send request when user clicks submit (confirm) button.
 *
 *      If `loadBody` plugin parameter is set to `true`, then form loaded
 *      in the modal body is also retrieved from this URL.
 *
 *      [!!] response from this URL spec: JSON {
 *        [html]: form HTML to be loaded into .modal-body (optional, used when `loadBody` is set),
 *        [success]: boolean value indicating form was processed successfully (optional)
 *      }
 *
 * - `data-method`:
 *      A method used to send the request to `data-action` URL when user clicks confirm (submit) button.
 *      By default, assumed to be "POST".
 *
 * - `data-reload-target-XXX`:
 *      An optional target that is reloaded after the modal is closed.
 *      Three cases are possible:
 *
 *      1) 'component' or 'parent': reloads a closest parent with '.uconfy-component-root' class
 *            by applying `uconfyComponent()` plugin to it.
 *
 *      2) '#some-component-id': a href to a component on the same page that must
 *            by reloaded. If found, the actions are the same as for case (1)
 *
 *      3) if 'window', if not set, or if component is not found, reloads the whole page.
 *
 * @param loadBody indicates whether need to load modal body from `data-action` URL.
 * @returns {*}
 * @author Andrey Larionov <larioandr@gmail.com>
 */
$.fn.uconfyModalDialog = function ({loadBody = false}  = {}) {
  const TEMPLATE_SUCCESS_CLASS = 'success-body';
  const TEMPLATE_ERROR_CLASS = 'error-body';
  const HIDE_AT_FINISH_QUERY = '.hide-at-finish';
  const FINISH_SCREEN_TIMEOUT = 2000;

  ///
  /// Remove modal dialog from the DOM
  ///
  function removeModal(id) {
    /*
     For some reason, modal is not removed from the first attempt.
     But second call really kills it.
     */
    let element = document.getElementById(id);
    let numAttempts = 0;
    while (!!element && numAttempts < 2) {
      element.parentNode.removeChild(element);
      element = document.getElementById(id);
      numAttempts++;
    }
  }

  ///
  /// Replace .modal-body with a content of a template with a given class.
  ///
  /// To make things work, modal dialog must contain a <template> element with
  /// a given class.
  ///
  /// It is expected, that modal have two templates with '.success-body' and
  /// '.error-body' classes for representing success and failure, correspondingly.
  ///
  const replaceModalBodyWithTemplate = function (modal, templateClass) {
    const template = modal.find(`template.${templateClass}`);
    if (!!template && !!template[0]) {
      modal.find('.modal-body').html(template.html());
      return true;
    }
    return false;
  };

  ///
  /// Hide all elements with '.hide-at-finish' class.
  ///
  const hideAtFinish = function (modal) {
    modal.find(HIDE_AT_FINISH_QUERY).addClass('d-none');
  };

  ///
  /// Show final screen and hide modal after the timeout.
  ///
  const showFinalScreen = function ($modal, $element, reloadTargets,
                                    templateClass, next = undefined) {
    console.log('next is ', next);
    hideAtFinish($modal);
    if (replaceModalBodyWithTemplate($modal, templateClass)) {
      setTimeout(() => {
        reloadTargets.forEach((target, _) =>
          reloadComponentOrWindow($element, target, next));
        $modal.modal('hide');
      }, FINISH_SCREEN_TIMEOUT);
    } else {
      reloadTargets.forEach((target, _) =>
        reloadComponentOrWindow($element, target, next));
      $modal.modal('hide');
    }
  };

  ///
  /// Handle user submits the modal form by sending AJAX request to `requestTarget`
  /// using method `requestMethod`.
  ///
  /// If request is processed successfully, display success message to the user and
  /// close the modal.
  ///
  /// If error appeared in form processing and validation, refresh the form with
  /// errors, received in HTML from the server, and continue working.
  ///
  /// In case of critical server error, display failure message to the user and hide.
  ///
  const handleConfirm = function($modal, requestTarget, requestMethod,
                                 $element, reloadTargets, next)
  {
    const _show = (tc, next = undefined) =>
      showFinalScreen($modal, $element, reloadTargets, tc, next);

    sendAjaxForm({
        $form: $modal.find('form'),
        requestTarget: requestTarget,
        requestMethod: requestMethod,
        successCallback: response => {
          // If request was processed without errors (e.g. invalid form data, NOT 403 and etc.),
          // then show final screen with indication of success.
          _show(TEMPLATE_SUCCESS_CLASS, response['next'] || next);
        },
        formErrorCallback: response => {
          // Otherwise, reload body and don't hide the form. This case is used, when
          // form errors were found and should be displayed to user in the same modal window.
          const $body = $modal.find('.modal-body');
          $body.html(response.html);
          $body.uconfy();
        },
        failureCallback: error => {
        /// If server-side error discovered (NOT invalid form, but 403, 500 etc. errors),
        /// display failure message to the user and close the modal.
        /// In particular, this case may appear if server is overloaded or some bug found.
          console.log(`[uconfyConfirm] ERROR in ${requestMethod} to ${requestTarget}: `, error);
          _show(TEMPLATE_ERROR_CLASS);
        },
      });
  };

  ///
  /// Load modal body from a given URL and call `success()` handler afterwards.
  ///
  const loadModalBody = function ($modal, url, success) {
    $.get(url, (response) =>
    {
      const $body = $modal.find('.modal-body');
      $body.html(response.html);
      $body.uconfy();
      success();
    });
  };

  ///
  /// Main plugin function:
  ///
  /// 1) Loads modal component when user clicks the toggler (button);
  ///
  /// 2) After modal component is loaded, it adds it to the body;
  ///
  /// 3) Sets handler to the modal main button click (it is labeled with
  ///    `data-toggle="submit"`);
  ///
  /// 4) Sets handler for modal hidden event: after hiding, modal is
  ///    deleted from the DOM.
  ///
  const applyPlugin = function($element) {
    const data = $element.data();

    const reqData = Object.keys(data)
      .filter(key => key !== 'componentUrl')
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const reloadTargets = getReloadTargets(data);

    $element.click(() =>
    {
      $.get(data['componentUrl'], reqData, ({html, id}) =>
      {
        $('body').children().append(html);
        const $modal = $(`#${id}`);

        $modal.on('click', '[data-toggle="submit"]', () =>
          handleConfirm($modal, data.action, data.method, $element, reloadTargets, data['next'])
        );
        $modal.on('hidden.bs.modal', () =>
          removeModal(id)
        );
        $modal.on('keypress', event => {
          const keyCode = (event.keyCode ? event.keyCode : event.which);
          if (keyCode === 13) {
            $modal.find('[data-toggle="submit"]').click();
          }
        });
        $modal.on('shown.bs.modal', () => {
          $modal.find('input:visible:enabled:first').focus();
        });

        if (loadBody) {
          loadModalBody($modal, data.action, () => $modal.modal('show'));
        } else {
          $modal.modal('show');
        }
      });
    });
  };

  return this.each(function () {
    applyPlugin($(this));
  });
};
