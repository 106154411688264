$.fn.uconfyFileControl = function ({} = {}) {

  function fileControlPlugin($el, {
    loadUrl,
    fileUrl,
    uploadUrl,
    deleteUrl,
    showLabel = false,
    label = 'Current file',
  } = {}) {
    // 1) Send GET to 'fileUrl' to get file info.
    $.get(fileUrl, function (response) {
      const data = {
        'upload_url': uploadUrl,
        'delete_url': deleteUrl,
        'file_name': response.name,
        'file_type': response.type,
        'file_url': response.url,
        'show_label': !!showLabel,
        'label': label,
      };

      // 2) After that, send GET to 'loadUrl' to load the plugin content,
      //    which depend on whether file exists, its type, etc.
      $.get(loadUrl, data, function (response) {
        const html = response.html;
        $el.html(html);
        $el.uconfy({
          afterSubmit: () => loadPlugin($el),
        });
      });
    })
  }

  function loadPlugin($el) {
    const data = $el.data();
    fileControlPlugin($el, data);
  }

  return this.each(function () {
    const $el = $(this);
    loadPlugin($el);
  });
};
