$.fn.uconfySelectReviewerInvitation = function () {

  const TEMPLATE_HTML = `
    <div class="d-flex align-items-center justify-content-between py-2 border-bottom">
      <img src="" alt="" class="avatar rounded rounded-circle mr-3">
      <div class="d-flex flex-column justify-content-between flex-fill mr-3">
        <div class="d-flex justify-content-between mb-1">
          <div class="js--name font-weight-900 text-dark"></div>
          <div class="font-weight-500 text-muted text-xs">
            #<span class="js--id"></span>
          </div>
        </div>
        <div class="js--place font-weight-500 text-xs mb-1 text-muted"></div>
        <div class="font-weight-800 text-xs text-muted">
          <i class="fas fa-graduation-cap mr-2"></i>
          <span class="js--degree"></span>
        </div>
      </div>
    </div>
  `;

  function applyPlugin($el) {
    const select = $el.find('select');
    const $button = $el.find('.uconfy-submit');
    const url = select.attr('data-url');

    select.select2({
      ajax: {
        url: url,
        dataType: 'json',
        delay: 100,
        data: function (params) {
          return {
            q: params.term,  // limit to 'q' only
          }
        }
      },
      placeholder: 'Search for a user',
      minimumInputLength: 1,
      templateSelection: formatSelection,
      templateResult: formatProfile,
    });

    function formatProfile(profile) {
      const $container = $(TEMPLATE_HTML);

      var placeParts = [];
      if (profile.country) placeParts.push(profile.country);
      if (profile.city) placeParts.push(profile.city);


      $container.find('img').attr('src', profile['avatar']);
      $container.find('.js--name').text(profile['name']);
      $container.find('.js--id').text(profile['id']);
      $container.find('.js--place').text(placeParts.join(", "));
      $container.find('.js--degree').text(profile['degree']);

      return $container;
    }

    function formatSelection(profile) {
      return profile.name;
    }

    $button.click(function () {
      var selectedData = select.select2('data');
      if (selectedData) {
        const profile = selectedData[0];
        $.ajax({
          url: profile['inviteUrl'],
          method: "POST",
          headers: {'X-CSRFToken': getCookie('csrftoken')},
          complete: () => {
            window.location.reload();
          }
        });
      }
    });
  }

  return this.each(function () {
    applyPlugin($(this));
  });
};
