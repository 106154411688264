/* jshint esversion: 6 */
$.fn.uconfySelectUser = function () {
  return this.each(function () {
    const $dialog = $(this);
    const form = $dialog.find($dialog.attr('data-form'));
    const inputName = $dialog.attr('data-form-input-name');
    const dataSource = $dialog.attr('data-source');
    const dataPreviewSource = $dialog.attr('data-preview-source');
    const formAction = $dialog.attr('data-form-action');
    const reloadTarget = $dialog.attr('data-reload-target');

    const formInput = form.find(`input[name='${inputName}']`);
    const button = form.find('.uconfy-submit-button');
    const btnHelpText = form.find('.uconfy-submit-help-text');

    const searchInput = $($dialog.find('input[type="search"]'));
    const previewColumn = $dialog.find('.uconfy-preview-column');
    // noinspection JSCheckFunctionSignatures
    const previewBox = previewColumn.find('.uconfy-preview-box');
    // noinspection JSCheckFunctionSignatures
    const loaderBox = previewColumn.find('.uconfy-loader-box');
    // noinspection JSCheckFunctionSignatures
    const errorBox = previewColumn.find('.uconfy-error-box');

    const usersList = $dialog.find('.uconfy-users-list');
    const usersListLabel = $dialog.find('.uconfy-users-list-label');
    const usersNotFoundLabel = $dialog.find('.uconfy-users-not-found-label');
    const enterSearchLabel = $dialog.find('.uconfy-users-enter-search-label');

    const state = {
      profileID: undefined,

      setProfileID: function (value) {
        if (this.profileID !== undefined) {
          $dialog
            .find(`.uconfy-user-item[data-profile-id='${state.profileID}']`)
            .removeClass('bg-secondary');
        }

        this.profileID = value;

        if (value === undefined) {
          toggleClasses(previewColumn, {add: 'd-none'});
          button.prop('disabled', true);
          toggleClasses(btnHelpText, {remove: 'd-none'});
          return;
        }
        button.prop('disabled', false);
        toggleClasses(btnHelpText, {add: 'd-none'});

        $dialog
          .find(`.uconfy-user-item[data-profile-id='${value}']`)
          .addClass('bg-secondary');

        previewColumn.removeClass('d-none');
        toggleClasses(errorBox, { add: 'd-none', remove: 'd-flex' });
        toggleClasses(previewBox, { add: 'd-none', remove: 'd-flex' });
        toggleClasses(loaderBox, { add: 'd-flex', remove: 'd-none' });

        $.get({
          url: dataPreviewSource,
          data: {id: value}
        }).then(resp => {
          if (!resp.success)
            throw Error(`${resp.code}: ${resp.error}`);
          return resp.html;
        }).then(html => {
          toggleClasses(loaderBox, { add: 'd-none', remove: 'd-flex' });
          toggleClasses(previewBox, { add: 'd-flex', remove: 'd-none' });
          previewBox.html(html);
        }).catch(error => {
          toggleClasses(loaderBox, { remove: 'd-flex', add: 'd-none'});
          toggleClasses(previewBox, { remove: 'd-flex', add: 'd-none'});
          toggleClasses(errorBox, { add: 'd-flex', remove: 'd-none'});
          console.log(error);
        });

        formInput.val(value);
      }
    };

    function toggleClasses(el, opts = {remove: undefined, add: undefined}) {
      if (opts.remove) {
        el.removeClass(opts.remove);
      }
      if (opts.add && !el.hasClass(opts.add)) {
        el.addClass(opts.add);
      }
    }

    // When user clicks any uconfy-user-item, we:
    // 1) remove highlight from the previously selected item (if any selected)
    // 2) set new profile ID
    // 3) highlight corresponding list item
    // 4) load details card
    $dialog.on('click', '.uconfy-user-item', function () {
      const profileID = $(this).attr('data-profile-id');
      state.setProfileID(profileID);
    });

    // Handle input term change
    function inputUpdateHandler() {
      const term = searchInput.val().trim();

      // state.setProfileID(undefined);

      if (term === '') {
        toggleClasses(usersList, { add: 'd-none' });
        toggleClasses(usersListLabel, { add: 'd-none' });
        toggleClasses(usersNotFoundLabel, { add: 'd-none' });
        toggleClasses(enterSearchLabel, { remove: 'd-none' });
      } else {
        $.get({url: dataSource, data: {q: term}}).then(
          resp => {
            toggleClasses(enterSearchLabel, { add: 'd-none' });
            if (resp.length === 0) {
              toggleClasses(usersList, { add: 'd-none' });
              toggleClasses(usersListLabel, { add: 'd-none' });
              toggleClasses(usersNotFoundLabel, { remove: 'd-none' });
            } else {
              toggleClasses(usersList, { remove: 'd-none' });
              toggleClasses(usersListLabel, { remove: 'd-none' });
              toggleClasses(usersNotFoundLabel, { add: 'd-none' });
              usersList.html(resp.html);
              // noinspection JSCheckFunctionSignatures
              usersListLabel.find('.uconfy-users-num').text(resp.length);
            }
          }
        );
      }
    }

    const reloadComponentOrWindow = function(reloadTarget) {
      let $component = undefined;
      if (reloadTarget !== 'window') {
        $component = $(reloadTarget);
      }
      if (!!$component && !!$component[0]) {
        $component.uconfyComponent();
        return;
      }
      window.location.reload();
    };


    // Handle form submit
    function handlePrimaryButtonClick(ev) {
      $.ajax({
        url: formAction,
        data: form.serialize(),
        method: 'POST',
        headers: {'X-CSRFToken': getCookie('csrftoken')},
      })
        .done(() => {
          reloadComponentOrWindow(reloadTarget);
          $dialog.modal('hide');
        })
        .fail((error) => {
          /// If server-side error discovered (NOT invalid form, but 403, 500 etc. errors),
          /// display failure message to the user and close the modal.
          /// In particular, this case may appear if server is overloaded or some bug found.
          console.log(`[uconfyConfirm] ERROR in POST to ${formAction}: `, error);
          $dialog.modal('hide');
        });
    }

    $dialog.on('show.bs.modal', () => {
      searchInput.val('');
      inputUpdateHandler();
      state.setProfileID(undefined);
    });

    // Initialization:
    form.attr('action', formAction);
    searchInput.on('input', inputUpdateHandler);
    searchInput.on('propertychange', inputUpdateHandler);  // for IE8
    button.on('click', handlePrimaryButtonClick);
    state.setProfileID(undefined);

    inputUpdateHandler();
  });
};
