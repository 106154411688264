$.fn.uconfyListToggler = function () {

  const UCONFY_LIST_CONTAINER_CLASS = '__uconfy-list-container';

  function plugin($el) {
    const elData = $el.data();
    const sizeUrl = elData['sizeUrl'];
    const dataUrl = elData['dataUrl'];
    const $labelShow = $el.find('.uconfy-label-show');
    const $labelHide = $el.find('.uconfy-label-hide');
    const $sizeSpan = $el.find('.uconfy-size');
    const $listRoot = $el.parents('.uconfy-list-root');

    let isShown = false;
    let isLoaded = false;
    let $container = null;

    // By default, display label showing content:
    $labelHide.hide();

    // Load number of items:
    $.get(sizeUrl, (data) => {
      // We expect that GET sizeUrl will return a JSON with key 'n'
      $sizeSpan.text(data['n']);
    });

    $el.click(() => {
      if (!isLoaded) {
        // 1) add a container to card
        $container = $(`<div class=".${UCONFY_LIST_CONTAINER_CLASS}"></div>`);
        $listRoot.append($container);

        // 2) load data into container
        $.get(dataUrl, (data) => {
          if (data['success']) {
            $container.html(data['html']);
            $container.uconfy({keepPreloaded: true});
          }
        });

        // 3) set isLoaded to true
        isLoaded = true;
      }
      if (isShown) {
        $container.hide();
        $labelHide.hide();
        $labelShow.show();
      } else {
        $container.show();
        $labelHide.show();
        $labelShow.hide();
      }
      isShown = !isShown;
    });
  }

  // Apply the plugin
  return this.each(function () {
    plugin($(this));
  });
};
