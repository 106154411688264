$.fn.uconfyAutoHideAlert = function (options = {}) {
  const delay = options['delay'] || 5000;

  return this.each(function () {
    const alert = $(this);
    let nextProgressUpdate = undefined;
    let delayRest = delay * 0.9;
    const delayStep = 250;

    setTimeout(() => {
      alert.alert('close');
      if (nextProgressUpdate !== undefined) {
        clearTimeout(nextProgressUpdate);
      }
    }, delay);

    const progressBar = alert.find('.progress-bar');
    if (progressBar.length > 0) {
      const updateProgress = () => {
        delayRest = Math.max(delayRest - delayStep, 0);
        const percentage = Math.ceil(delayRest / delay * 100);
        progressBar.css('width', `${percentage}%`);
        if (percentage > 1) {
          nextProgressUpdate = setTimeout(updateProgress, delayStep);
        }
      };

      nextProgressUpdate = setTimeout(updateProgress, delayStep);
    }
  });
};
